/* You can add global styles to this file, and also import other style files */

// Bootstrap
@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css");
// MDB
@import url("https://cdnjs.cloudflare.com/ajax/libs/mdb-ui-kit/3.10.1/mdb.min.css");
// Google Font
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
// Font Awesome Pro
@import url("https://cdn.leanhduc.pro.vn/font-awesome/pro-5.15.3/css/all.css");

.btn-success,
.btn-primary {
    background-color: #119489 !important;
}

.btn-danger {
    background-color: #dc3545 !important;
}

.note-success {
    border-color: #119489 !important;
    background-color: #f0fff8 !important;
}

h1,
h2,
h3 {
    color: #119489 !important;
}

pre {
    margin: 0;
}

a {
    cursor: pointer;
    color: #119489;

    &:hover {
        color: #008075;
    }
}